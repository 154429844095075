import Swiper, { Navigation, Pagination } from 'swiper';
import Burger from './components/burger';
import GLightbox from 'glightbox';
import '@justinribeiro/lite-youtube';
import MicroModal from 'micromodal';
import Choices from 'choices.js';
import Inputmask from 'inputmask';
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/mobilefriendly';
import {
  add,
  destroy,
  list,
  quantity,
  remove,
  total,
} from 'cart-localstorage';

Swiper.use([Navigation, Pagination]);

const media = window.matchMedia('(max-width: calc(992px - 1px))');
if (media.matches) {
  const burger = new Burger({
    node: document.querySelector('.js_header-menu'),
    anchor: document.querySelector('.header__main'),
  });
}

// search
const searchBtnShow = document.querySelector('.js_show-search');
const searchBtnClose = document.querySelector('.js_close-search');
const searchBlock = document.querySelector('.js_search');

searchBtnShow.addEventListener('click', () => {
  searchBlock.classList.toggle('active');
});
searchBtnClose.addEventListener('click', () => {
  searchBlock.classList.remove('active');
});

// hero slide
try {
  const heroSlider = new Swiper('.hero__slider.swiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    autoHeight: true,
    pagination: {
      el: '.hero__slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.hero__slider-next',
      prevEl: '.hero__slider-prev',
    },
  });
} catch (e) {}

try {
  const videoLightbox = new GLightbox({
    selector: '.js-glightbox-video',
    type: 'video',
    source: 'youtube',
  });
} catch (e) {}

try {
  const localVideoLightbox = new GLightbox({
    plyr: {
      css: '',
      js: '/theme/assets/js/vendor/plyr.js',
    },
    selector: '.js-glightbox-video-local',
    type: 'video',
    source: 'local',
  });
} catch (e) {}


try {
  const serviceSlider = new Swiper('.service__slider.swiper', {
    slidesPerView: 'auto',
    spaceBetween: 10,
    pagination: false,
    // breakpoints: {
    //   320: {
    //     slidesPerView: 1,
    //   },
    //   480: {
    //     slidesPerView: 2,
    //   },
    //   640: {
    //     slidesPerView: 3,
    //   },
    //   1020: {
    //     slidesPerView: 4
    //   }
    // },
    navigation: {
      nextEl: '.service__slider-next',
      prevEl: '.service__slider-prev',
    },
  });
} catch (e) {}

// experts list show/hide
try {
  const expertsBtns = document.querySelectorAll('.js_experts-btn');
  expertsBtns.forEach((expertsBtn) => {
    expertsBtn.addEventListener('click', () => {
      const expertsBtnParent = expertsBtn.closest('.js_experts-spec');
      expertsBtnParent.classList.toggle('open');
      if (expertsBtnParent.classList.contains('open')) {
        expertsBtn.textContent = 'Скрыть';
      } else {
        expertsBtn.textContent = 'Показать все';
      }
    });
  });
} catch (e) {}

// modal example
try {
  const modalOverlay = document.querySelector('.js-modal-overlay');
  const modalConfig = {
    openClass: 'modal--show',
    openTrigger: 'data-modal-open',
    closeTrigger: 'data-modal-close',
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    onShow: () => {
      modalOverlay.classList.add('modal-overlay--show');
      modalOverlay.classList.remove('modal-overlay--hidden');
    },
    onClose: () => {
      modalOverlay.classList.add('modal-overlay--hidden');
      modalOverlay.classList.remove('modal-overlay--show');
    },
  };

  MicroModal.init(modalConfig);
} catch (e) {}

// custom select
try {
  const selects = document.querySelectorAll('.js-choice');
  selects.forEach((select) => {
    const choices = new Choices(select, {
      searchEnabled: false,
      searchChoices: false,
      itemSelectText: '',
    });
  });
  // eslint-disable-next-line no-empty
} catch (e) {}

// mask tel
try {
  const telInput = document.querySelectorAll('.js_tel');
  Inputmask({ mask: '+7 (999) 999-9999', showMaskOnHover: false }).mask(telInput);
  // eslint-disable-next-line no-empty
} catch (e) {}

// mask passport
try {
  const passportInput = document.querySelectorAll('.js_passport');
  Inputmask({ mask: '99-99 999999', showMaskOnHover: false }).mask(passportInput);
  // eslint-disable-next-line no-empty
} catch (e) {}

// mask inn
try {
  const innInput = document.querySelectorAll('.js_inn');
  Inputmask({ mask: '999999999999', showMaskOnHover: false }).mask(innInput);
  // eslint-disable-next-line no-empty
} catch (e) {}

// Calendar
try {
  const picker = new Litepicker({
    element: document.getElementById('reviews-calendar'),
    format: 'DD.MM.YYYY',
    minDate: new Date(),
    lang: 'ru-RU',
    plugins: ['mobilefriendly'],
    showTooltip: false,
    startDate: new Date(),
    buttonText: 'ДД/ММ/ГГГГ',
  });
  // eslint-disable-next-line no-empty
} catch (e) {}
try {
  const taxPicker = new Litepicker({
    element: document.getElementById('tax-calendar'),
    format: 'DD.MM.YYYY',
    lang: 'ru-RU',
    plugins: ['mobilefriendly'],
    showTooltip: false,
    buttonText: 'ДД/ММ/ГГГГ',
  });
  // eslint-disable-next-line no-empty
} catch (e) {}
try {
  const materialsPicker = new Litepicker({
    element: document.getElementById('materials-calendar'),
    format: 'DD.MM.YYYY',
    lang: 'ru-RU',
    plugins: ['mobilefriendly'],
    showTooltip: false,
    buttonText: 'ДД/ММ/ГГГГ',
  });
  // eslint-disable-next-line no-empty
} catch (e) {}
try {
  const operationPicker = new Litepicker({
    element: document.getElementById('operation-calendar'),
    format: 'DD.MM.YYYY',
    lang: 'ru-RU',
    plugins: ['mobilefriendly'],
    showTooltip: false,
    buttonText: 'ДД/ММ/ГГГГ',
  });
  // eslint-disable-next-line no-empty
} catch (e) {}

try {
  const reviewPicker = new Litepicker({
    element: document.getElementById('review-calendar'),
    format: 'DD.MM.YYYY',
    lang: 'ru-RU',
    plugins: ['mobilefriendly'],
    showTooltip: false,
    buttonText: 'ДД/ММ/ГГГГ',
  });
  // eslint-disable-next-line no-empty
} catch (e) {}

// image lightbox
window.imageLightbox = new GLightbox({
  selector: '.js-glightbox-image',
  type: 'image',
});

try {
  const contentLighboxLinks = document.querySelectorAll('.js-glightbox');
  contentLighboxLinks.forEach((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      const type = item.dataset.lightboxType;
      const contentLightbox = new GLightbox({});
      switch(type) {
        case 'iframe':
          const content = item.dataset.content;
          contentLightbox.setElements([
            {
              content: `<iframe src="${content}" width="100%" height="100%"></iframe>`,
            },
          ]);
          contentLightbox.open();
          break;
        case 'jsonGallery':
          const baseUrl = document.head.baseURI;
          const dataEndpoint = item.dataset.endpoint;
          fetch(baseUrl + dataEndpoint)
          .then((res) => res.json())
          .then((res) => {
            if (!res.error) {
              const elements = res.map((item) => {
                const imagePath = `${baseUrl}uploads/${item.img}`;

                return {
                  'href': imagePath,
                  'type': 'image',
                  'title': item.title,
                };
              });

              contentLightbox.setElements(elements);
              contentLightbox.open();
            } else {
              console.error('Error retrieving gallery');
            }
          })
          .catch((err) => console.error(err));
          break;
        default:
          return false;
      }

    });
  })
  // eslint-disable-next-line no-empty
} catch (e) {}

/*
try {
// eslint-disable-next-line no-undef
  $(document).on('af_complete', (e, r) => {
  // eslint-disable-next-line no-undef, func-names
    AjaxForm.Message.success = function () {};
    const id = r.form.parents('.modal').attr('id');
    MicroModal.close(id);
    setTimeout(() => MicroModal.show('modal-thanks', modalConfig), 500);
  });
// eslint-disable-next-line no-empty
} catch (e) {}
*/

// slider example
/*
try {
  const fullpageSlider = new Swiper('.js-fullpage-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    updateOnImagesReady: true,
    preloadImages: true,
    loop: true,
    breakpoints: {
      575: {
        slidesPerView: 2,
      },
    },
    on: {
      imagesReady() {
        imageLightbox.reload();
      },
    },
  });

  const fullpageSliderNextBtn = fullpageSlider.el.nextElementSibling.querySelector('.js-fullpage-slider-next');
  const fullpageSliderPrevBtn = fullpageSlider.el.nextElementSibling.querySelector('.js-fullpage-slider-prev');

  fullpageSliderNextBtn.addEventListener('click', () => fullpageSlider.slideNext());
  fullpageSliderPrevBtn.addEventListener('click', () => fullpageSlider.slidePrev());
// eslint-disable-next-line no-empty
} catch (e) {}
*/


// whatsapp link
const whatsappLinks = document.querySelectorAll('[data-whatsapp-link]');

if (whatsappLinks && window.matchMedia("(max-width: 767px)").matches) {
  whatsappLinks.forEach((link) => {
    link.href = link.href.replace('https://web.whatsapp.com/send/?phone=', 'whatsapp://send?phone=');
  });
}

const modalLinks = document.querySelectorAll('[data-modal-open]');

if (modalLinks) {
  modalLinks.forEach((link) => {
    link.addEventListener('click', (e) => e.preventDefault());
  })
}

const phoneMask = new Inputmask("+7(999)999-99-99");

const phoneInputs = document.querySelectorAll('input[type="tel"]');

if (phoneInputs) {
  for (let input of phoneInputs) {
    try {
      phoneMask.mask(input);
    } catch(e) {}
  }
}


try {
  const glightboxGalleryLinks = document.querySelectorAll('.js-glightbox-gallery-link');
  const uploadsPath = '/uploads/';
  for (let link of glightboxGalleryLinks) {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const galleryItemsString = e.target.dataset.galleryItems ? e.target.dataset.galleryItems  : e.target.parentNode.dataset.galleryItems;
      if (galleryItemsString) {
        let galleryItems = JSON.parse(galleryItemsString);
        galleryItems = galleryItems.map((i) => {
          return {
            href: uploadsPath + i.img,
            type: 'image'
          }
        });

        const galleryLighbox = new GLightbox({
          elements: galleryItems,
          loop: true,
        });

        galleryLighbox.open();
      }
    });
  }
// eslint-disable-next-line no-empty
} catch(e) {}

document.addEventListener('alpine:init', () => {
  Alpine.store('goods', {
    init() {
      if (document.querySelector('.goods.section') || document.querySelector('.basket.section')) {
        this.getGoodsData();
        this.getCartTotalPrice();
      }

      this.getCartData();
    },
    delOneItem(MIGX_id) {
      this.items = this.items.map((i) => {
        if (i.MIGX_id === MIGX_id && i.qty > 0) {
          i.qty--;
        }
        return i;
      });
    },
    addOneItem(MIGX_id) {
      this.items = this.items.map((i) => {
        if (i.MIGX_id === MIGX_id) {
          i.qty++;
        }
        return i;
      });
    },
    delOneCartItem(id) {
      quantity(id, -1);
      this.getCartData();
      this.getCartTotalPrice();
    },
    addOneCartItem(id) {
      quantity(id, +1);
      this.getCartData();
      this.getCartTotalPrice();
    },
    getGoodsData() {
      fetch(`${this.baseUrl}api/goods.json`)
        .then((res) => res.json())
        .then((res) => {
          if (!res.error) {
            this.items.push(
              ...res.map((i) => {
                i.qty = 0;
                i.image = Alpine.store('goods').baseUrl + i.image;
                return i;
              }),
            );
            this.isLoading = false;
          } else {
            console.error('Error retrieving goods');
          }
        })
        .catch((err) => console.error(err));
    },
    getCartData() {
      this.cartItems = list();
    },
    updateCartData() {
      // todo
    },
    addToCart(MIGX_id, qty) {
      if (qty > 0) {
        const [itemData] = this.items.filter((i) => i.MIGX_id === MIGX_id);
        add(
          {
            id: itemData.MIGX_id,
            name: itemData.name,
            image: itemData.image,
            price: itemData.price,
          },
          qty,
        );
        this.getCartData();
        this.items = this.items.map((i) => {
          if (i.MIGX_id === MIGX_id) {
            i.qty = 0;
          }
          return i;
        });
      }
    },
    removeFromCart(id) {
      remove(id);
      this.getCartData();
      this.getCartTotalPrice();
    },
    clearCart() {
      destroy();
      this.getCartData();
      console.log('Cart cleared');
    },
    getCartTotalPrice() {
      this.cartTotalPrice = total();
    },
    getCartGoodsString() {
      if (this.cartItems.length) {
        let result = '';
        this.cartItems.forEach((i) => {
          result += `${i.name}:${i.quantity}:${i.price};`;
        });
        console.log(result);
        return result;
      }
    },
    baseUrl: document.head.baseURI,
    isLoading: true,
    items: [],
    cartItems: [],
    cartTotalPrice: 0,
    deliveryType: 'courier',
  });
});
