export default class Burger {
  constructor(obj) {
    this.createAnchor(obj.anchor);
    this.createOverflow();
    this.replaceNavbar(obj.node);
  }

  createAnchor(anchor) {
    this.anchor = {};
    this.anchor.wrapper = anchor;

    this.anchor.button = document.createElement('button');
    this.anchor.button.classList.add('burger-anchor');

    for (let i = 0; i < 3; i++) {
      const span = document.createElement('span');
      span.classList.add('burger-anchor__span');
      this.anchor.button.append(span);
    }

    this.anchor.wrapper.append(this.anchor.button);

    let listener;
    this.anchor.button.addEventListener('click', listener = () => {
      this.open();
    });

    this.anchor.toTop = () => {
      const c = this.anchor.button.getBoundingClientRect();
      const top = c.top + document.body.scrollTop;
      const { left } = c;
      this.anchor.button.style.top = `${top}px`;
      this.anchor.button.style.left = `${left}px`;

      document.querySelector('.header').style.position = 'static';
      document.querySelector('.header .container').style.position = 'static';
      this.anchor.wrapper.style.position = 'static';
      this.anchor.button.classList.add('burger-anchor-active');

      this.anchor.button.removeEventListener('click', listener);
      this.anchor.button.addEventListener('click', listener = () => {
        this.close();
      });
    };

    this.anchor.toDefault = () => {
      document.querySelector('.header').style.position = 'relative';
      document.querySelector('.header .container').style.position = 'relative';
      this.anchor.wrapper.style.position = 'relative';
      this.anchor.button.classList.remove('burger-anchor-active');

      this.anchor.button.removeAttribute('style');

      this.anchor.button.removeEventListener('click', listener);
      this.anchor.button.addEventListener('click', listener = () => {
        this.open();
      });
    };
  }

  createOverflow() {
    this.overflow = document.createElement('div');
    this.overflow.classList.add('overflow');
    this.overflow.event = document.createElement('div');
    this.overflow.event.classList.add('overflowEvent');

    this.overflow.append(this.overflow.event);
    document.body.append(this.overflow);
  }

  replaceNavbar(node) {
    this.navbar = node;
    this.navbar.classList.display = 'block';
    this.navbar.container = this.navbar.children[0];
    this.overflow.append(this.navbar);
  }

  open() {
    function fadeIn(el) {
      let opacity = 0.1;
      el.overflow.event.style.display = 'block';
      const timer = setInterval(() => {
        if (opacity >= 1) {
          if (!el.navbar.classList.contains('burger-active')) {
            el.navbar.classList.add('burger-active');
            el.anchor.toTop();
          }
          clearInterval(timer);
        }

        el.overflow.event.style.opacity = opacity;
        el.overflow.event.style.filter = `alpha(opacity=${opacity * 100})`;
        opacity += opacity * 0.1;
      }, 10);
    }

    this.overflow.style.display = 'block';
    fadeIn(this);
  }

  close() {
    const fadeOut = (el) => {
      let opacity = 1;
      const timer = setInterval(() => {
        if (opacity <= 0.1) {
          clearInterval(timer);
          el.style.display = 'none';
        }

        el.event.style.opacity = opacity;
        el.event.style.filter = `alpha(opacity=${opacity * 100})`;
        opacity -= opacity * 0.1;
      }, 10);
    };

    if (this.navbar.classList.contains('burger-active')) {
      this.navbar.classList.remove('burger-active');
      this.anchor.toDefault();
    }
    fadeOut(this.overflow);
  }
}
